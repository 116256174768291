import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import { Layout, ProjectHeader, ProjectPagination, SEO } from '../components'
import config from '../../config/site'

const BG = styled.div`
  background-color: ${props => props.theme.colors.bg};
  position: relative;
  padding: 2rem 0 0 0;
`

const OuterWrapper = styled.div`
  padding: 0 ${props => props.theme.contentPadding};
  margin: -10rem auto 0 auto;
`

const InnerWrapper = styled.div`
  position: relative;
  max-width: ${props => `${props.theme.maxWidths.project}px`};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Project = ({ pageContext: { slug, prev, next }, data: { project: postNode } }) => {
  const project = postNode.frontmatter

  if (!project.photo) {
    console.warn(`No photo for ${project.name}!`)
    return null
  }

  return (
    <Layout customSEO>
      <SEO postPath={slug} postNode={postNode} postSEO />
      <ProjectHeader avatar={config.avatar} name={config.name} text={postNode.body} {...project} />
      <BG>
        <OuterWrapper>
          <InnerWrapper>
            <Img
              alt={project.description}
              key={project.photo.childImageSharp.fluid.src}
              fluid={project.photo.childImageSharp.fluid}
              style={{
                margin: '3rem 0',
                maxWidth: '100%',
                width: project.photo.childImageSharp.fluid.presentationWidth,
                height: 'auto',
              }}
            />
          </InnerWrapper>
          <ProjectPagination next={next} prev={prev} />
        </OuterWrapper>
      </BG>
    </Layout>
  )
}

export default Project

Project.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    next: PropTypes.object,
    prev: PropTypes.object,
  }),
  data: PropTypes.shape({
    project: PropTypes.object.isRequired,
    images: PropTypes.object.isRequired,
  }).isRequired,
}

Project.defaultProps = {
  pageContext: PropTypes.shape({
    next: null,
    prev: null,
  }),
}

export const pageQuery = graphql`
  query($slug: String!, $absolutePathRegex: String!) {
    images: allFile(
      filter: {
        absolutePath: { regex: $absolutePathRegex }
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1600, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    project: mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt
      parent {
        ... on File {
          mtime
          birthtime
        }
      }
      frontmatter {
        description
        origine
        dimensions
        matiere
        photo {
          childImageSharp {
            resize(width: 800) {
              src
            }
            fluid(maxWidth: 1600, quality: 90) {
              presentationWidth
              presentationHeight
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
